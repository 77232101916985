// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

.bottom-buffer {
    margin-bottom: 20px;
}

.swiper-button-next {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-color: azure;
    color: azure;
  }
  
  .swiper-button-next::after {
    display: none;
    background-color: azure;
    color: azure;
  }